<template>
  <ModalMain
    :media-type="mediaType"
    @close="close"
  >
    <div class="w-full relative z-0">
<iframe
        v-if="mediaModalData?.media === 'yt' && mediaModalData.id"
        :src="`https://www.youtube.com/embed/${mediaModalData?.id}?rel=0&playsinline=1`"
        frameborder="0"
        allowfullscreen
        class="w-full aspect-video z-10 relative"
      />

      <Spinner class="absolute left-1/2 top-1/2 -mt-4 -ml-4 z-0"/>
</div>
  </ModalMain>
</template>

<script>
import { mapWritableState } from 'pinia';

export default defineComponent({
  name: 'ModalMediaMain',
  data() {
    return {};
  },
  computed: {
    ...mapWritableState(useUiStore, ['showMediaModal', 'mediaModalData',]),
    mediaType() {
      return this.mediaModalData?.media === 'yt' ? 'video' : null;
    },
  },
  watch: {
    mediaModalData(val) {
      this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: `Media ${val?.media}`, value: val?.id, });
    },
  },
  methods: {
    close() {
      this.showMediaModal = false;
      this.mediaModalData = null;
      this.$router.push({ path: this.$route.path, });
    },
  },
});
</script>
